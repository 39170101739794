var i18n;

function getLocale() {
    return i18n.lang;
}

function changeLocale(locale) {
    i18n.lang = locale;
}

function setI18n(v) {
    i18n = v;
}


export { changeLocale, getLocale, setI18n };