import mapi from "@xlegend/mapi2/lib/mapi";


async function loginByToken(token) {
    mapi.logoutProcess();
    let ret = await mapi.queryV1("account.get_memberid");
    if (ret['data']['status'] == "1") {
        try {
            mapi.loginProcess({
                xToken: token,
                id: ret['data']['data']['memberid']
            });
        } catch (e) {
            console.log(e);
        }
        return true;
    } else {
        return false;
    }
}


export { loginByToken }